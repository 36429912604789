/* SVG RULES */

.hand, 
.hand-double, 
.hand-flick, 
.hand-hold, 
.hand-rock, 
.hand-tap, 
.hand-x, 
.hand-y {
  fill: #fff;
  stroke: #000;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.arrow-down, 
.arrow-head, 
.arrow-left, 
.arrow-right, 
.arrow-up, 
.arrow-up-1, 
.arrow-up-2, 
.arrow-up-3,   
.hold-1,
.hold-2, 
.line-horizontal, 
.line-rotate, 
.line-vertical, 
.notes, 
.tap-1, 
.tap-2 {
  fill: transparent;
  stroke: #fff;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.arrow-up-3, 
.swipe-horizontal, 
.swipe-rotate, 
.swipe-vertical {
  opacity: .75;
}

.hold-1, 
.hold-2, 
.notes {
  opacity: 0;
}

/* ANIMATION KEYFRAMES */

@keyframes tap-double {
  0% {
    transform: rotateX(0deg);
  }
  10% {
    transform: rotateX(12.5deg);
  }
  25% {
    transform: rotateX(25deg);
  }
  35% {
    transform: rotateX(10deg);
  }
  50% {
    transform: rotateX(25deg);
  }
}

@keyframes tap {
  0% {
    transform: rotateX(0deg);
  }
  10% {
    transform: rotateX(12.5deg);
  }
  25% {
    transform: rotateX(25deg);
  }
}

@keyframes tap-circle {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  75% {
    transform: scale(1.05);
    opacity: .6;
  }
  80% {
    transform: scale(1);
    opacity: .5;
  }
}

@keyframes hold {
  0% {
    transform: rotateX(0deg);
  }
  10% {
    transform: rotateX(12.5deg);
  }
  30% {
    transform: rotateX(25deg);
  }
  80% {
    transform: rotateX(25deg);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  30% {
    opacity: .75
  }
  80% {
    opacity: .75;
  }
}

@keyframes swipe-x {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(50px) rotateZ(10deg);
  }
  50% {
    transform: translateX(0px);
  }
  75% {
    transform: translateX(-50px) rotateZ(-10deg);
  }
}

@keyframes swipe-y {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(50px) rotateZ(-10deg);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-50px) rotateZ(10deg);
  }
}

@keyframes flick-fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
}

@keyframes flick {
  0% {
    transform: rotateZ(0deg);
  }
  10% {
    transform: translateY(-12px) rotateZ(50deg);
  }
  25% {
    transform: rotateZ(5deg);
  }
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  10% {
    transform: translateY(-10deg) rotateZ(-20deg);
  }
  50% {
    transform: rotateZ(45deg);
  }
}

@keyframes rock-on {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
}

@keyframes note {
  0% {
    transform: scale(0) rotateZ(0deg);
    opacity: 0;
  }
  20% {
    transform: scale(1.1) rotateZ(10deg);
  }
  40% {
    transform: scale(0.9) rotateZ(-10deg);
  }
  50% {
    opacity: .75;
  }
  60% {
    transform: scale(1.1) rotateZ(10deg);
  }
  80% {
    transform: scale(0.9) rotateZ(-10deg);
  }
}

/* SVG ANIMATION */

.wrapper * {
  transform-origin: 50% 50%;
  perspective: 100px;
}

.hand {
  transform-origin: 25% 50%;
}

.hand-tap {
  animation: tap 1.25s ease-out backwards;
  animation-iteration-count:infinite;
}

.hand-double {
  animation: tap-double 1.25s ease-out backwards;
  animation-iteration-count:infinite;
}

.tap-1,
.tap-2 {
  animation: tap-circle 1.25s ease-out backwards;
  animation-iteration-count:infinite;
}

.hand-hold {
  animation: hold 1.25s ease-out backwards;
  animation-iteration-count:infinite;
}

.hold-1, .hold-2 {
  animation: fade 1.25s ease-in backwards;
  animation-iteration-count:infinite;
}

.tap-2 {
  animation-delay: 0.2s;
}

.hand-x {
  animation: swipe-x 1.25s ease-in-out backwards;
  animation-iteration-count:infinite;
}

.hand-y {
  animation: swipe-y 1.25s ease-in-out backwards;
  animation-iteration-count:infinite;
}
 
.hand-flick {
  animation: flick 1.25s ease-out backwards;
  animation-iteration-count:infinite;
}

.arrows {
  opacity: 0;
  animation: flick-fade 1.25s ease-out backwards;
  animation-iteration-count:infinite;
}

.hand, 
.swipe-rotate {
  animation: spin 1.25s ease-in-out backwards;
  animation-iteration-count:infinite;
}

.hand-rock {
  animation: rock-on 1.25s ease-out backwards;
  animation-iteration-count:infinite;
}

.notes {
  animation: note 1.25s ease-out backwards;
  animation-iteration-count:infinite;
}