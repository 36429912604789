@font-face {
  font-family: gotham;
  font-style: normal;
  font-weight: 400;
  src: local("Gotham"), url('assets/fonts/GothamBook.woff') format("woff");
}
@font-face {
  font-family: gotham;
  font-style: italic;
  font-weight: 400;
  src: local("Gotham"), url('assets/fonts/GothamBookItalic.woff') format("woff");
}
@font-face {
  font-family: gotham;
  font-style: normal;
  font-weight: 300;
  src: local("Gotham"), url('assets/fonts/GothamLight.woff') format("woff");
}
@font-face {
  font-family: gotham;
  font-style: italic;
  font-weight: 300;
  src: local("Gotham"), url('assets/fonts/GothamLightItalic.woff') format("woff");
}
@font-face {
  font-family: gotham;
  font-style: normal;
  font-weight: 500;
  src: local("Gotham"), url('assets/fonts/GothamMedium.woff') format("woff");
}
@font-face {
  font-family: gotham;
  font-style: normal;
  font-weight: 500;
  src: local("Gotham"), url('assets/fonts/GothamMedium_1.woff') format("woff");
}
@font-face {
  font-family: gotham;
  font-style: italic;
  font-weight: 500;
  src: local("Gotham"), url('assets/fonts/GothamMediumItalic.woff') format("woff");
}
@font-face {
  font-family: gotham;
  font-style: normal;
  font-weight: 700;
  src: local("Gotham"), url('assets/fonts/GothamBold.woff') format("woff");
}
@font-face {
  font-family: gotham;
  font-style: normal;
  font-weight: 700;
  src: local("Gotham"), url('assets/fonts/Gotham-Bold.woff') format("woff");
}
@font-face {
  font-family: gotham;
  font-style: italic;
  font-weight: 700;
  src: local("Gotham"), url('assets/fonts/GothamBoldItalic.woff') format("woff");
}
@font-face {
  font-family: gotham black;
  font-style: normal;
  font-weight: 500;
  src: local("Gotham Black"), url('assets/fonts/Gotham-Black.woff') format("woff");
}
@font-face {
  font-family: gotham light;
  font-style: normal;
  font-weight: 500;
  src: local("Gotham Light"), url('assets/fonts/Gotham-Light.woff') format("woff");
}
@font-face {
  font-family: gotham thin;
  font-style: normal;
  font-weight: 500;
  src: local("Gotham Thin"), url('assets/fonts/Gotham-Thin.woff') format("woff");
}
@font-face {
  font-family: gotham xlight;
  font-style: normal;
  font-weight: 500;
  src: local("Gotham XLight"), url('assets/fonts/Gotham-XLight.woff') format("woff");
}
@font-face {
  font-family: gotham book;
  font-style: italic;
  font-weight: 500;
  src: local("Gotham Book"), url('assets/fonts/Gotham-BookItalic.woff') format("woff");
}
@font-face {
  font-family: gotham thin;
  font-style: italic;
  font-weight: 500;
  src: local("Gotham Thin"), url('assets/fonts/Gotham-ThinItalic.woff') format("woff");
}
@font-face {
  font-family: gotham ultra;
  font-style: italic;
  font-weight: 500;
  src: local("Gotham Ultra"), url('assets/fonts/Gotham-UltraItalic.woff') format("woff");
}
@font-face {
  font-family: gotham xlight;
  font-style: italic;
  font-weight: 500;
  src: local("Gotham XLight"), url('assets/fonts/Gotham-XLightItalic.woff') format("woff");
}
