html {
  font-size: 1.125rem;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  html {
    font-size: 0.84375rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  html {
    font-size: 0.675rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  html {
    font-size: 0.9rem;
  }
}

.v2-type-n {
  font-size: 1.125rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-n {
    font-size: 0.84375rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-n {
    font-size: 0.675rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-n {
    font-size: 0.9rem;
  }
}

.v2-type-n-b {
  font-size: 1.125rem;
  font-family: "ibm_plex_sans_bold", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-n-b {
    font-size: 0.84375rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-n-b {
    font-size: 0.675rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-n-b {
    font-size: 0.9rem;
  }
}

.v2-type-t {
  font-size: 1.875rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-t {
    font-size: 1.40625rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-t {
    font-size: 1.125rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-t {
    font-size: 1.5rem;
  }
}

.v2-type-t-b {
  font-size: 1.875rem;
  font-family: "ibm_plex_sans_bold", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-t-b {
    font-size: 1.40625rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-t-b {
    font-size: 1.125rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-t-b {
    font-size: 1.5rem;
  }
}

.v2-type-m {
  font-size: 2.25rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-m {
    font-size: 1.6875rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-m {
    font-size: 1.35rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-m {
    font-size: 1.8rem;
  }
}

.v2-type-m-b {
  font-size: 2.25rem;
  font-family: "ibm_plex_sans_bold", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-m-b {
    font-size: 1.6875rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-m-b {
    font-size: 1.35rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-m-b {
    font-size: 1.8rem;
  }
}

.v2-type-l {
  font-size: 3.125rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-l {
    font-size: 2.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-l {
    font-size: 1.875rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-l {
    font-size: 2.5rem;
  }
}

.v2-type-l-nf {
  font-size: 3.125rem;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-l-nf {
    font-size: 2.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-l-nf {
    font-size: 1.875rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-l-nf {
    font-size: 2.5rem;
  }
}

.v2-type-l-b {
  font-size: 3.125rem;
  font-family: "ibm_plex_sans_bold", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-l-b {
    font-size: 2.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-l-b {
    font-size: 1.875rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-l-b {
    font-size: 2.5rem;
  }
}

.v2-type-xl {
  font-size: 3.875rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-xl {
    font-size: 2.90625rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-xl {
    font-size: 2.325rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-xl {
    font-size: 3.1rem;
  }
}

.v2-type-xl-b {
  font-size: 3.875rem;
  font-family: "ibm_plex_sans_bold", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-xl-b {
    font-size: 2.90625rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-xl-b {
    font-size: 2.325rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-xl-b {
    font-size: 3.1rem;
  }
}

.v2-type-sm {
  font-size: 1rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}

.v2-type-sm-b {
  font-size: 1rem;
  font-family: "ibm_plex_sans_bold", sans-serif;
}

.v2-type-xsm {
  font-size: 0.8rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-xsm {
    font-size: 0.6rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-xsm {
    font-size: 0.48rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-xsm {
    font-size: 0.64rem;
  }
}

.v2-type-xsm-b {
  font-size: 0.8rem;
  font-family: "ibm_plex_sans_bold", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-xsm-b {
    font-size: 0.6rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-xsm-b {
    font-size: 0.48rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-xsm-b {
    font-size: 0.64rem;
  }
}

.v2-type-xxsm {
  font-size: 0.5rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-xxsm {
    font-size: 0.375rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-xxsm {
    font-size: 0.3rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-xxsm {
    font-size: 0.4rem;
  }
}

.remove-lh {
  margin-bottom: -0.5rem;
}

.bold-text {
  font-family: "ibm_plex_sans_bold", sans-serif;
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nowrap-text {
  white-space: nowrap;
}

.size-xs {
  font-size: 0.8rem;
}

.size-s {
  font-size: 1rem;
}

.size-m {
  font-size: 2.25rem;
}

.size-l {
  font-size: 3.125rem;
}

.size-title {
  font-size: 1.875rem;
}

.size-normal {
  font-size: 1.125rem;
}

.text-padding-left-xs {
  padding-left: 0.5rem;
}

.text-padding-left-s {
  padding-left: 1rem;
}

.text-padding-left-m {
  padding-left: 1.5rem;
}

.text-margin-left-xs {
  margin-left: 0.5rem;
}

.text-margin-left-s {
  margin-left: 1rem;
}

.text-margin-left-m {
  margin-left: 1.5rem;
}

@media screen and (max-width: 1400px), print {
  .v2-type-xxsm {
    font-size: 0.5rem;
  }
}
html {
  font-size: 1.125rem;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  html {
    font-size: 0.84375rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  html {
    font-size: 0.675rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  html {
    font-size: 0.9rem;
  }
}

.v2-type-n {
  font-size: 1.125rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-n {
    font-size: 0.84375rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-n {
    font-size: 0.675rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-n {
    font-size: 0.9rem;
  }
}

.v2-type-n-b {
  font-size: 1.125rem;
  font-family: "ibm_plex_sans_bold", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-n-b {
    font-size: 0.84375rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-n-b {
    font-size: 0.675rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-n-b {
    font-size: 0.9rem;
  }
}

.v2-type-t {
  font-size: 1.875rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-t {
    font-size: 1.40625rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-t {
    font-size: 1.125rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-t {
    font-size: 1.5rem;
  }
}

.v2-type-t-b {
  font-size: 1.875rem;
  font-family: "ibm_plex_sans_bold", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-t-b {
    font-size: 1.40625rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-t-b {
    font-size: 1.125rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-t-b {
    font-size: 1.5rem;
  }
}

.v2-type-m {
  font-size: 2.25rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-m {
    font-size: 1.6875rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-m {
    font-size: 1.35rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-m {
    font-size: 1.8rem;
  }
}

.v2-type-m-b {
  font-size: 2.25rem;
  font-family: "ibm_plex_sans_bold", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-m-b {
    font-size: 1.6875rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-m-b {
    font-size: 1.35rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-m-b {
    font-size: 1.8rem;
  }
}

.v2-type-l {
  font-size: 3.125rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-l {
    font-size: 2.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-l {
    font-size: 1.875rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-l {
    font-size: 2.5rem;
  }
}

.v2-type-l-nf {
  font-size: 3.125rem;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-l-nf {
    font-size: 2.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-l-nf {
    font-size: 1.875rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-l-nf {
    font-size: 2.5rem;
  }
}

.v2-type-l-b {
  font-size: 3.125rem;
  font-family: "ibm_plex_sans_bold", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-l-b {
    font-size: 2.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-l-b {
    font-size: 1.875rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-l-b {
    font-size: 2.5rem;
  }
}

.v2-type-xl {
  font-size: 3.875rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-xl {
    font-size: 2.90625rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-xl {
    font-size: 2.325rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-xl {
    font-size: 3.1rem;
  }
}

.v2-type-xl-b {
  font-size: 3.875rem;
  font-family: "ibm_plex_sans_bold", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-xl-b {
    font-size: 2.90625rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-xl-b {
    font-size: 2.325rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-xl-b {
    font-size: 3.1rem;
  }
}

.v2-type-sm {
  font-size: 1rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}

.v2-type-sm-b {
  font-size: 1rem;
  font-family: "ibm_plex_sans_bold", sans-serif;
}

.v2-type-xsm {
  font-size: 0.8rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-xsm {
    font-size: 0.6rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-xsm {
    font-size: 0.48rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-xsm {
    font-size: 0.64rem;
  }
}

.v2-type-xsm-b {
  font-size: 0.8rem;
  font-family: "ibm_plex_sans_bold", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-xsm-b {
    font-size: 0.6rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-xsm-b {
    font-size: 0.48rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-xsm-b {
    font-size: 0.64rem;
  }
}

.v2-type-xxsm {
  font-size: 0.5rem;
  font-family: "ibm_plex_sans_text", sans-serif;
}
@media (min-width: 1255px) and (max-width: 1689px) {
  .v2-type-xxsm {
    font-size: 0.375rem;
  }
}
@media (min-width: 769px) and (max-width: 1254px) {
  .v2-type-xxsm {
    font-size: 0.3rem;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .v2-type-xxsm {
    font-size: 0.4rem;
  }
}

.remove-lh {
  margin-bottom: -0.5rem;
}

.bold-text {
  font-family: "ibm_plex_sans_bold", sans-serif;
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nowrap-text {
  white-space: nowrap;
}

.size-xs {
  font-size: 0.8rem;
}

.size-s {
  font-size: 1rem;
}

.size-m {
  font-size: 2.25rem;
}

.size-l {
  font-size: 3.125rem;
}

.size-title {
  font-size: 1.875rem;
}

.size-normal {
  font-size: 1.125rem;
}

.text-padding-left-xs {
  padding-left: 0.5rem;
}

.text-padding-left-s {
  padding-left: 1rem;
}

.text-padding-left-m {
  padding-left: 1.5rem;
}

.text-margin-left-xs {
  margin-left: 0.5rem;
}

.text-margin-left-s {
  margin-left: 1rem;
}

.text-margin-left-m {
  margin-left: 1.5rem;
}

@media screen and (max-width: 1400px), print {
  .v2-type-xxsm {
    font-size: 0.5rem;
  }
}
html, section {
  background-color: #3B3B3B;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

[hidden] {
  display: none !important;
}

.card {
  background-color: #FFF;
  height: 100%;
  text-align: center;
  transition: 0.5s;
}

.highlighted-card {
  background-color: #EF3742;
  color: #FFF;
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  max-height: 100vh;
}

.no-background-color {
  background-color: rgba(255, 255, 255, 0);
}

.flex-none {
  flex: none;
}

.left-text {
  text-align: left;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.hidden-in-desktop {
  display: none;
}

.animated {
  transition: 0.5s;
}

#yaxis-scrollbar-style::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 0.4rem;
  transition: 0.5s;
}

#yaxis-scrollbar-style::-webkit-scrollbar {
  width: 0.5rem;
  transition: 0.5s;
}

#yaxis-scrollbar-style::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 0.4rem;
  transition: 0.5s;
}

#yaxis-scrollbar-style:hover::-webkit-scrollbar-track {
  background-color: #D4D4D4;
  transition: 0.5s;
}

#yaxis-scrollbar-style:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.5s;
}

#xaxis-scrollbar-style::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 0.4rem;
  transition: 0.5s;
}

#xaxis-scrollbar-style::-webkit-scrollbar {
  height: 0.5rem;
  transition: 0.5s;
}

#xaxis-scrollbar-style::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 0.4rem;
  transition: 0.5s;
}

#xaxis-scrollbar-style:hover::-webkit-scrollbar-track {
  background-color: #D4D4D4;
  transition: 0.5s;
}

#xaxis-scrollbar-style:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.5s;
}

.v2-margin-xs {
  margin: 0.5rem;
}

.v2-margin-s {
  margin: 1rem;
}

.v2-margin-m {
  margin: 1.5rem;
}

.v2-margin-l {
  margin: 2rem;
}

.v2-left-padding-s {
  padding-left: 0.75rem;
}

.hyper-link {
  cursor: pointer;
}

.hover-reaction:hover {
  opacity: 0.5;
  transition: 0.5s;
}

.actived-btn {
  opacity: 0.5;
  transition: 0.5s;
}

.is-vertical-center {
  display: flex;
  align-items: center;
}

.is-total-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.is-mobile-menu {
  height: 55vh;
  position: relative;
}

.threesixty {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
}

.threesixty_images {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.threesixty_images img {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
}

.threesixty .spinner {
  width: 3rem;
  display: block;
  margin: 0 auto;
  height: 2rem;
  background: #333;
  background: rgba(0, 0, 0, 0.7);
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  border-radius: 0.5rem;
}

.threesixty .spinner span {
  font-family: Arial, "MS Trebuchet", sans-serif;
  font-size: 1rem;
  font-weight: bolder;
  color: #FFF;
  text-align: center;
  line-height: 2rem;
  display: block;
}

.previous-image {
  visibility: hidden;
}

.current-image {
  visibility: visible;
}

.is-full-height {
  height: 100%;
}

/* SVG RULES */
.hand,
.hand-double,
.hand-flick,
.hand-hold,
.hand-rock,
.hand-tap,
.hand-x,
.hand-y {
  fill: #fff;
  stroke: #000;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.arrow-down,
.arrow-head,
.arrow-left,
.arrow-right,
.arrow-up,
.arrow-up-1,
.arrow-up-2,
.arrow-up-3,
.hold-1,
.hold-2,
.line-horizontal,
.line-rotate,
.line-vertical,
.notes,
.tap-1,
.tap-2 {
  fill: transparent;
  stroke: #fff;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.arrow-up-3,
.swipe-horizontal,
.swipe-rotate,
.swipe-vertical {
  opacity: 0.75;
}

.hold-1,
.hold-2,
.notes {
  opacity: 0;
}

/* ANIMATION KEYFRAMES */
@keyframes tap-double {
  0% {
    transform: rotateX(0deg);
  }
  10% {
    transform: rotateX(12.5deg);
  }
  25% {
    transform: rotateX(25deg);
  }
  35% {
    transform: rotateX(10deg);
  }
  50% {
    transform: rotateX(25deg);
  }
}
@keyframes tap {
  0% {
    transform: rotateX(0deg);
  }
  10% {
    transform: rotateX(12.5deg);
  }
  25% {
    transform: rotateX(25deg);
  }
}
@keyframes tap-circle {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  75% {
    transform: scale(1.05);
    opacity: 0.6;
  }
  80% {
    transform: scale(1);
    opacity: 0.5;
  }
}
@keyframes hold {
  0% {
    transform: rotateX(0deg);
  }
  10% {
    transform: rotateX(12.5deg);
  }
  30% {
    transform: rotateX(25deg);
  }
  80% {
    transform: rotateX(25deg);
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.75;
  }
  80% {
    opacity: 0.75;
  }
}
@keyframes swipe-x {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(50px) rotateZ(10deg);
  }
  50% {
    transform: translateX(0px);
  }
  75% {
    transform: translateX(-50px) rotateZ(-10deg);
  }
}
@keyframes swipe-y {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(50px) rotateZ(-10deg);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-50px) rotateZ(10deg);
  }
}
@keyframes flick-fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
}
@keyframes flick {
  0% {
    transform: rotateZ(0deg);
  }
  10% {
    transform: translateY(-12px) rotateZ(50deg);
  }
  25% {
    transform: rotateZ(5deg);
  }
}
@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  10% {
    transform: translateY(-10deg) rotateZ(-20deg);
  }
  50% {
    transform: rotateZ(45deg);
  }
}
@keyframes rock-on {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
}
@keyframes note {
  0% {
    transform: scale(0) rotateZ(0deg);
    opacity: 0;
  }
  20% {
    transform: scale(1.1) rotateZ(10deg);
  }
  40% {
    transform: scale(0.9) rotateZ(-10deg);
  }
  50% {
    opacity: 0.75;
  }
  60% {
    transform: scale(1.1) rotateZ(10deg);
  }
  80% {
    transform: scale(0.9) rotateZ(-10deg);
  }
}
/* SVG ANIMATION */
.wrapper * {
  transform-origin: 50% 50%;
  perspective: 100px;
}

.hand {
  transform-origin: 25% 50%;
}

.hand-tap {
  animation: tap 1.25s ease-out backwards;
  animation-iteration-count: infinite;
}

.hand-double {
  animation: tap-double 1.25s ease-out backwards;
  animation-iteration-count: infinite;
}

.tap-1,
.tap-2 {
  animation: tap-circle 1.25s ease-out backwards;
  animation-iteration-count: infinite;
}

.hand-hold {
  animation: hold 1.25s ease-out backwards;
  animation-iteration-count: infinite;
}

.hold-1, .hold-2 {
  animation: fade 1.25s ease-in backwards;
  animation-iteration-count: infinite;
}

.tap-2 {
  animation-delay: 0.2s;
}

.hand-x {
  animation: swipe-x 1.25s ease-in-out backwards;
  animation-iteration-count: infinite;
}

.hand-y {
  animation: swipe-y 1.25s ease-in-out backwards;
  animation-iteration-count: infinite;
}

.hand-flick {
  animation: flick 1.25s ease-out backwards;
  animation-iteration-count: infinite;
}

.arrows {
  opacity: 0;
  animation: flick-fade 1.25s ease-out backwards;
  animation-iteration-count: infinite;
}

.hand,
.swipe-rotate {
  animation: spin 1.25s ease-in-out backwards;
  animation-iteration-count: infinite;
}

.hand-rock {
  animation: rock-on 1.25s ease-out backwards;
  animation-iteration-count: infinite;
}

.notes {
  animation: note 1.25s ease-out backwards;
  animation-iteration-count: infinite;
}