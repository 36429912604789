@import 'atoms';
$type-phone: 320px;
$type-tablet: 769px;
$type-desktop: 1255px;
$type-widescreen: 1690px;
$type-fullhd: 1408px;

@mixin scaled-font($fontsize) {
    font-size: $fontsize;
    @media (min-width: $type-desktop) and (max-width: $type-widescreen - 1px)  {
        font-size: $fontsize * 0.75;
    }
    @media (min-width: $type-tablet) and (max-width: $type-desktop - 1px)  {
        font-size: $fontsize * 0.60;
    }
    @media (min-width: $type-phone) and (max-width: $type-tablet - 1px)  {
        font-size: $fontsize * 0.80;
    }
}

html {
  @include scaled-font($v2-font-size-normal);
}

.v2-type-n {
    @include scaled-font($v2-font-size-normal);
    font-family: $v2-type-text;
}
.v2-type-n-b {
    @include scaled-font($v2-font-size-normal);
    font-family: $v2-type-bold;
}
.v2-type-t {
    @include scaled-font($v2-font-size-title);
    font-family: $v2-type-text;
}
.v2-type-t-b {
    @include scaled-font($v2-font-size-title);
    font-family: $v2-type-bold;
}
.v2-type-m {
    @include scaled-font($v2-font-size-medium);
    font-family: $v2-type-text;
}
.v2-type-m-b {
    @include scaled-font($v2-font-size-medium);
    font-family: $v2-type-bold;
}
.v2-type-l {
    @include scaled-font($v2-font-size-large);
    font-family: $v2-type-text;
}
.v2-type-l-nf {
    @include scaled-font($v2-font-size-large);
}
.v2-type-l-b {
    @include scaled-font($v2-font-size-large);
    font-family: $v2-type-bold;
}
.v2-type-xl {
    @include scaled-font($v2-font-size-xlarge);
    font-family: $v2-type-text;
}
.v2-type-xl-b {
    @include scaled-font($v2-font-size-xlarge);
    font-family: $v2-type-bold;
}
.v2-type-sm {
    font-size: $v2-font-size-small;
    font-family: $v2-type-text;
}
.v2-type-sm-b {
    font-size: $v2-font-size-small;
    font-family: $v2-type-bold;
}
.v2-type-xsm {
    @include scaled-font($v2-font-size-xsmall);
    font-family: $v2-type-text;
}
.v2-type-xsm-b {
    @include scaled-font($v2-font-size-xsmall);
    font-family: $v2-type-bold;
}
.v2-type-xxsm {
    @include scaled-font($v2-font-size-xxsmall);
    font-family: $v2-type-text;
}

.remove-lh {
    margin-bottom: -0.5rem;
}

.bold-text {
    font-family: $v2-type-bold;
}

.unselectable {
   -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.nowrap-text {
    white-space: nowrap;
}

.size-xs {
    font-size: $v2-font-size-xsmall;
}

.size-s {
    font-size: $v2-font-size-small;
}

.size-m {
    font-size: $v2-font-size-medium;
}

.size-l {
    font-size: $v2-font-size-large;
}

.size-title {
    font-size: $v2-font-size-title;
}

.size-normal {
    font-size: $v2-font-size-normal;
}

.text-padding-left-xs {
    padding-left: .5rem;
}

.text-padding-left-s {
    padding-left: 1rem;
}

.text-padding-left-m {
    padding-left: 1.5rem;
}

.text-margin-left-xs {
    margin-left: .5rem;
}

.text-margin-left-s {
    margin-left: 1rem;
}

.text-margin-left-m {
    margin-left: 1.5rem;
}


//mobile font
@media screen and (max-width: 1400px), print {
  .v2-type-xxsm {
    font-size: 0.5rem;
  }
}